@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wrapper {
        aspect-ratio: var(--aspect-ratio, auto);
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-md);
    }

    .wistia-wrapper {
        aspect-ratio: var(--aspect-ratio, auto);
        height: auto;
        overflow: hidden;
        width: 100%;
    }

    .caption {
        color: var(--text-color);
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-300);
        font-weight: var(--g-font-weight-light);
        line-height: var(--g-line-height-200);
        margin: 0;
        text-align: var(--text-align);
    }
}
