@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .component-wrapper {
        [tabindex="-1"]:focus-visible {
            outline: var(--g-border-width-sm) solid
                var(--s-color-brand-primary-standard);
            outline-offset: var(--g-spacing-xs);
        }
    }
}
