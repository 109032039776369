@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wrapper {
        color: var(--s-color-text-primary);
        display: none;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-regular);
        outline: var(--g-border-width-xs) solid transparent;
        padding: 0;

        @include breakpoint-md {
            display: grid;
            grid-template-columns: 180px 1fr;
        }

        @include breakpoint-lg {
            grid-template-columns: 1fr 650px;
        }

        @media (min-width: 1024px) {
            grid-template-columns: 406px 1fr;
        }
    }

    .left-wrapper,
    .right-wrapper {
        overflow: hidden;
        position: relative;
    }

    .tour-image {
        height: 100%;
        overflow: hidden;
        border-top-left-radius: var(--c-deals-tour-card-wrapper-border-radius);
        border-bottom-left-radius: var(
            --c-deals-tour-card-wrapper-border-radius
        );

        picture {
            display: flex;
            height: 100%;
        }

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    .wishlist {
        position: absolute;
        right: var(--g-spacing-md);
        top: var(--g-spacing-sm);
        z-index: 2;
        color: var(--s-color-brand-primary-standard);
    }

    .merchandising-label {
        left: var(--g-spacing-md);
        position: absolute;
        top: var(--g-spacing-md);
        z-index: 2;
    }

    .product-page-link {
        color: inherit;
        font-weight: var(--g-font-weight-bold);
        text-decoration: none;
        font-size: var(--g-font-size-500);

        &:focus-visible {
            border-radius: var(--s-border-radius-interactive-focus);
            color: var(--s-color-brand-primary-standard);
            outline-color: currentColor;
            outline-offset: var(--g-spacing-xxs);
            outline-style: solid;
            outline-width: var(--g-border-width-sm);
            text-decoration: underline;
        }
    }

    .trip-length {
        font-weight: var(--g-font-weight-light);
        margin-top: var(--g-spacing-xs);
    }
    .rating-wrapper {
        margin-top: var(--g-spacing-xs);
    }

    .tour-details {
        display: grid;
        gap: var(--g-spacing-md);
        grid-template-columns: 20px 650px;
        padding: var(--g-spacing-md);
        position: relative;

        @include breakpoint-md {
            margin: auto;
            max-width: 600px;
        }

        @media (min-width: 1024px) {
            max-width: none;
        }
    }

    .product-page-link {
        &::before {
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .deals-list {
        border-color: var(--s-color-separator-standard);
        border-style: solid;
        border-width: var(--g-border-width-xs) 0;
        margin: 0;
        overflow: auto;
        padding: 0;
        list-style: none;

        &--no-bottom-border {
            @include breakpoint-md {
                border-bottom: none;
            }
            @include breakpoint-lg {
                border-bottom: var(--g-border-width-xs) solid
                    var(--s-color-separator-standard);
            }
        }
    }

    .trip-builder-link-wrapper {
        display: block;
        padding: var(--g-spacing-sm);
        position: relative;
        text-align: center;

        a {
            position: static;

            &::before {
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }
}
