@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .grid {
        display: flex;
        overflow: hidden;
        flex-wrap: nowrap;
        margin-top: var(--g-spacing-lg);
        margin-bottom: var(--g-spacing-xl);
    }

    .review {
        .review-rating {
            margin-bottom: var(--g-spacing-md);
        }
        .review-author {
            margin-bottom: var(--g-spacing-md);
        }
        .review-content {
            margin-bottom: var(--g-spacing-md);
        }
        .review-footer {
            margin-top: var(--g-spacing-md);
        }
    }
}
