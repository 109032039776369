@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .border {
        position: relative;
        border: 0 solid var(--border-styles-color, transparent);

        &--all {
            border-width: var(--border-styles-width);
        }

        &--left-right,
        &--left {
            border-left-width: var(--border-styles-width);
        }

        &--left-right,
        &--right {
            border-right-width: var(--border-styles-width);
        }

        &--top-bottom,
        &--top {
            border-top-width: var(--border-styles-width);
        }

        &--top-bottom,
        &--bottom {
            border-bottom-width: var(--border-styles-width);
        }

        &-image {
            // HACK: This is a workaround for border radius with border images
            // you can't use `border-radius` and `border-image` together so
            // we're using a background image to achieve the same effect. A white
            // background is used to hide the border image behind the content.
            $fill: var(--s-color-surface-secondary, #fff);
            background-image: linear-gradient($fill, $fill),
                var(--border-styles-image-src, none),
                var(--border-styles-gradient, none);
            background-color: var(--border-styles-color);
            background-origin: border-box;
            background-clip: content-box, border-box, border-box;
            border-color: transparent;
            z-index: 1;
        }
    }

    .border-radius {
        &--standard {
            border-radius: var(--s-border-radius-background-standard);
            overflow: clip;
        }
        &--none {
            border-radius: 0;
        }
    }
}
