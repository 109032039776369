@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .reviews-summary {
        border-bottom: 1px solid var(--s-color-separator-standard);
        margin-bottom: var(--g-spacing-md);
        padding-bottom: var(--g-spacing-md);

        @include breakpoint-md {
            margin-bottom: var(--g-spacing-lg);
            padding-bottom: var(--g-spacing-lg);
        }
    }

    .average-rating {
        align-items: center;
        display: flex;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-1000);
        font-weight: var(--g-font-weight-bold);
        line-height: var(--g-line-height-1000);
        gap: var(--g-spacing-sm);
        margin-bottom: var(--g-spacing-xs);

        @include breakpoint-md {
            font-size: var(--g-font-size-1200);
            line-height: 100%;
        }
    }

    .pagination-header {
        display: flex;
        flex-direction: column;
        margin-bottom: var(--g-spacing-lg);
        gap: var(--g-spacing-md);

        & > *:first-child {
            order: 1;
        }

        @include breakpoint-md {
            flex-direction: row;
            justify-content: space-between;

            & > *:first-child {
                order: 0;
            }
        }
    }

    .skip {
        &--visually-hidden:not(:focus-visible) {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }

        margin: var(--g-spacing-md) 0;
        scroll-margin-top: 100px;
    }

    .pagination {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: var(--g-spacing-md);

        .buttons {
            display: flex;
            gap: var(--g-spacing-md);
            margin-top: var(--g-spacing-sm);
        }
    }
}
