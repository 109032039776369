@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-md);
        aspect-ratio: var(--aspect-ratio, auto);
    }

    .border {
        display: flex;
        overflow: hidden;
        aspect-ratio: var(--aspect-ratio, auto);
        justify-content: center;
        border-radius: var(--border-radius, 0);

        &--circular {
            border-radius: 50%;
        }
    }

    .image {
        display: flex;

        img {
            aspect-ratio: var(--aspect-ratio, auto);
            height: auto;
            width: 100%;
        }
    }

    .caption {
        color: var(--text-color);
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-300);
        font-weight: var(--g-font-weight-light);
        line-height: var(--g-line-height-200);
        margin: 0;
        text-align: var(--text-align);
    }
}
