@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .page-link-grid {
        gap: var(--g-spacing-lg);

        @include breakpoint-md {
            gap: var(--g-spacing-xl);
        }
    }
}
