@use "@wojo/ui/mixins" as *;

.grid {
    margin-top: var(--g-spacing-lg);
    margin-bottom: var(--g-spacing-lg);
    @include breakpoint-md {
        margin-bottom: var(--g-spacing-xl);
    }
}

.card {
    grid-template-columns: var(--c-tour-card-grid-image-width) calc(
            100% - var(--c-tour-card-grid-image-width)
        );
    display: grid;
    width: 100%;

    @include breakpoint-md {
        display: flex;
        flex-direction: column;
    }

    .card-content {
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-sm);
        margin-left: var(--g-spacing-md);
        margin-top: var(--g-spacing-md);

        @include breakpoint-md {
            margin-left: 0;
        }
    }
}

.forced-desktop-card {
    width: 100%;
    display: flex;
    flex-direction: column;

    .card-content {
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-sm);
        margin-top: var(--g-spacing-md);
    }
    .image {
        aspect-ratio: 3/2;
        height: unset;
        width: 100%;
    }
}

.image {
    height: 194px;
    @include breakpoint-md {
        aspect-ratio: 3/2;
        height: unset;
        width: 100%;
    }
}
