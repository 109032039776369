@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .tab-eyebrow {
        color: inherit;
        margin-bottom: var(--g-spacing-sm);
    }

    .tab-title {
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-500);
        font-weight: var(--g-font-weight-bold);
        line-height: var(--g-line-height-500);

        @include for-tablet-up {
            font-size: var(--g-font-size-700);
            line-height: 130%;
        }
    }

    .tab-list {
        padding-bottom: var(--g-spacing-lg);
        position: relative;

        @include for-tablet-up {
            padding-bottom: var(--g-spacing-xl);
        }

        &::after {
            border-bottom: var(--g-border-width-xs) solid
                var(--s-color-separator-standard);
            bottom: var(--g-spacing-lg);
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            z-index: -1;

            @include for-tablet-up {
                bottom: var(--g-spacing-xl);
            }
        }

        .tab-eyebrow {
            color: inherit;
        }

        .tab-text {
            display: flex;
            flex-direction: column;
            // TODO: Set the value to `start` as a part of WWB-277
            justify-content: flex-start;
            height: 100%;
            width: 100%;

            &--left {
                // TODO: Set the value to `start` as a part of WWB-277
                align-items: flex-start;
                text-align: left;
            }

            &--center {
                align-items: center;
                text-align: center;
            }
        }
    }

    .content-bottom-border {
        padding-bottom: var(--g-spacing-lg);
        position: relative;

        @include for-tablet-up {
            padding-bottom: var(--g-spacing-xl);
        }

        &::after {
            border-bottom: var(--g-border-width-xs) solid
                var(--s-color-separator-standard);
            bottom: 0;
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            z-index: -1;
        }
    }
}
