@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .grid {
        margin-top: var(--g-spacing-lg);
        margin-bottom: var(--g-spacing-lg);
        @include breakpoint-md {
            margin-bottom: var(--g-spacing-xl);
        }
    }

    .card {
        grid-template-columns: var(--c-tour-card-grid-image-width) calc(
                100% - var(--c-tour-card-grid-image-width)
            );
        display: grid;
        width: 100%;

        .image {
            height: 100%;
            width: 100px;
        }

        .card-content {
            display: flex;
            flex-direction: column;
            gap: var(--g-spacing-sm);
            margin-left: var(--g-spacing-md);
            margin-top: var(--g-spacing-md);

            @include breakpoint-md {
                margin-left: 0;
            }
        }
    }
}
