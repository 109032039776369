@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .viewport {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-top-left-radius: var(--c-deals-tour-card-wrapper-border-radius);
        border-bottom-left-radius: var(
            --c-deals-tour-card-wrapper-border-radius
        );
    }

    .track {
        display: grid;
        grid-template-columns: repeat(var(--nb-items), 100%);
        padding: 0px;
        height: 100%;
        width: 100%;
        transition: translate3d var(--g-duration-xshort) ease;
    }

    .slide {
        width: 100%;
        height: 100%;
        overflow: hidden;

        picture {
            display: flex;
            height: 100%;
            width: 100%;
        }
    }

    .slide-link {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}
