@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .container {
        display: grid;
        grid-template-columns: auto;
        gap: var(--g-spacing-lg);

        @include for-tablet-up {
            display: grid;
            gap: var(--g-spacing-xl);
            grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
        }

        @include breakpoint-lg {
            gap: var(--g-spacing-xxl);
        }

        > div {
            display: flex;
            width: 100%;
        }

        &--smaller-media {
            > div {
                grid-column: span 12;
            }
            @include for-tablet-up {
                > div:first-child {
                    grid-column: span 4;
                }
                > div:last-child {
                    grid-column: span 8;
                }
            }
        }
        &--larger-media {
            > div {
                grid-column: span 12;
            }
            @include for-tablet-up {
                > div:first-child {
                    grid-column: span 8;
                }
                > div:last-child {
                    grid-column: span 4;
                }
            }
        }

        &--reverse {
            @include for-tablet-up {
                > div:first-child {
                    order: 2;
                }
                > div:last-child {
                    order: 1;
                }
            }
        }
    }

    .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &--x-left {
            align-items: flex-start;
        }
        &--x-center {
            align-items: center;
            text-align: center;
        }
    }

    .content-item {
        &:not(:last-child) {
            padding-bottom: var(--g-spacing-md);
        }
        &.actions {
            &:not(:last-child) {
                padding-bottom: var(--g-spacing-md);
            }
        }
        &--spacing-sm {
            padding-bottom: var(--g-spacing-sm);
        }
    }
}
