@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .modal {
        padding: var(--g-spacing-lg);
        header {
            padding: 0;
        }

        &-heading {
            margin: var(--g-spacing-md) 0;
        }
    }
}
