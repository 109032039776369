@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .no-results-banner {
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-sm);
        align-items: center;
        justify-content: center;
        border-radius: var(--s-border-radius-background-standard);
        background: var(--s-color-surface-primary);
        padding: var(--g-spacing-lg);
        margin-top: var(--g-spacing-lg);
        text-align: center;

        @include breakpoint-md {
            margin-top: var(--g-spacing-xl);
            padding: var(--g-spacing-xl);
        }

        svg {
            height: var(--g-size-xs);
        }
    }
}
