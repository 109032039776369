@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .modal {
        ::-webkit-scrollbar {
            width: 4px;
        }
        ::-webkit-scrollbar-track {
            background: transparent;
        }
        ::-webkit-scrollbar-thumb {
            background-color: rgba(155, 155, 155, 0.5);
            border-radius: 20px;
            border: transparent;
        }
    }
    .modal-header {
        padding: 0;
    }
    .modal-body {
        height: 100%;
        overflow-y: scroll;
        padding: 0;
        @include breakpoint-lg {
            height: auto;
        }
    }
    .split-content {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include breakpoint-md {
            display: grid;
            gap: var(--g-spacing-xl);
            grid-template-columns: 48% 48%;
            height: 100vh;
        }

        @include breakpoint-lg {
            overflow-y: auto;
        }
    }
    .image-container {
        > div {
            margin-bottom: var(--g-spacing-md);
        }

        @include breakpoint-lg {
            overflow-y: auto;
            height: 100%;
            width: 100%;
            position: relative;
            overflow-x: hidden;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    .content-container {
        width: 100%;
        @include breakpoint-lg {
            position: sticky;
            top: 0;
            overflow-y: auto;
        }
    }

    .image-count {
        display: none;

        @include breakpoint-md {
            display: block;
            margin-bottom: var(--g-spacing-md);
        }
    }

    .detail-title {
        margin-bottom: var(--g-spacing-xs);
    }

    .rating {
        margin-bottom: var(--g-spacing-sm);
    }

    .author-and-travel-date {
        margin-bottom: var(--g-spacing-sm);
    }

    .description {
        display: none;
        margin-bottom: var(--g-spacing-lg);

        @include breakpoint-md {
            display: block;
        }
    }

    .detail-body {
        margin-bottom: var(--g-spacing-lg);
    }

    .spacer {
        display: inline-block;
        margin-right: var(--g-spacing-xs);
        width: 0;
    }
}
