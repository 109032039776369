@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .review-wrapper {
        margin-bottom: var(--g-spacing-lg);
    }

    .detail-title {
        margin-bottom: var(--g-spacing-xs);
    }

    .rating-and-author {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-sm);
        margin-bottom: var(--g-spacing-sm);
        @include breakpoint-md {
            align-items: center;
            flex-direction: row;
        }
    }

    .detail-body {
        margin-bottom: var(--g-spacing-md);
    }

    .spacer {
        display: inline-block;
        margin-right: var(--g-spacing-xs);
        width: 0;
    }

    .response {
        background: var(--s-color-surface-quiet);
        border-radius: var(--s-border-radius-background-standard);
        outline: var(--g-border-width-xs) solid transparent;
        padding: var(--g-spacing-md);
        margin-bottom: var(--g-spacing-xs);
    }

    .travel-date-and-product-link {
        margin: var(--g-spacing-md) 0;
    }

    .link {
        color: var(--g-color-primary-standard);
    }
}
