@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .border-container {
        border-radius: inherit;
        display: flex;
        box-sizing: border-box;
        min-height: 100%;
        min-width: 100%;
        overflow: hidden;
    }

    .image-graphic {
        display: inline-flex;
        align-items: flex-start;
        max-height: 100px;
        max-width: 100px;

        img,
        picture {
            max-height: inherit;
        }
    }

    .cta {
        white-space: nowrap;
    }

    .text-banner-container {
        display: flex;
        box-sizing: border-box;
        min-height: 100%;
        min-width: 100%;

        &-background-color {
            background-color: var(--text-banner-background-color);
        }

        &-background-gradient {
            background: linear-gradient(
                var(--text-banner-gradient-angle),
                var(--text-banner-background-start-color),
                var(--text-banner-background-end-color)
            );
        }

        &--standard {
            flex-direction: column;
            padding: var(--g-spacing-lg);

            .image-graphic {
                margin-right: 0;
                padding-bottom: var(--g-spacing-md);
            }

            .cta {
                margin-top: var(--g-spacing-md);
            }

            @include breakpoint-md {
                flex-direction: row;
                padding: var(--g-spacing-xl);
                .image-graphic {
                    margin-right: var(--g-spacing-md);
                    padding-bottom: 0;
                }
            }

            @include breakpoint-xl {
                flex-direction: row;

                .image-graphic {
                    margin-right: var(--g-spacing-lg);
                }

                .cta {
                    margin-top: 0;
                    margin-left: var(--g-spacing-xl);
                }
            }
        }

        &--compact {
            flex-direction: column;
            padding: var(--g-spacing-lg);

            .image-graphic {
                margin-right: 0;
                padding-bottom: var(--g-spacing-sm);
            }

            .cta {
                margin-top: var(--g-spacing-sm);
            }

            @include breakpoint-md {
                flex-direction: row;
                justify-content: flex-start;
                padding: var(--g-spacing-md);

                .image-graphic {
                    margin-right: var(--g-spacing-md);
                    padding-bottom: 0;
                }
            }

            @include breakpoint-xl {
                .cta {
                    margin-top: 0;
                    margin-left: 0;
                }
            }
        }
    }

    .text-banner-content {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @include breakpoint-xl {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
}
