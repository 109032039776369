@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .grid-wrapper {
        margin-bottom: var(--g-spacing-lg);
        margin-top: var(--g-spacing-lg);

        @include breakpoint-md {
            margin-bottom: var(--g-spacing-xl);
            margin-top: var(--g-spacing-xl);
        }
    }
}
