@use "@wojo/ui/mixins" as *;

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--g-spacing-lg) 0;
    flex-wrap: wrap;
}

.link-list {
    display: grid;
    margin: 0;
    margin-bottom: var(--g-spacing-lg);
    padding: 0;
    gap: var(--g-spacing-lg) var(--g-spacing-lg);
    @include breakpoint-sm {
        gap: var(--g-spacing-lg);
        grid-template-columns: none;
        justify-content: start;
        padding-bottom: var(--g-spacing-sm);
        margin-bottom: 0;
    }
}

.link-list-item {
    list-style: none;
    text-align: center;
    margin: 0;
    width: 100%;
    &:nth-child(1) {
        grid-column: 1;
    }
    &:nth-child(2) {
        grid-column: 2;
    }
    &:nth-child(3) {
        grid-column: span 2;
        grid-row: 2;
    }
    @include breakpoint-sm {
        text-align: left;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            grid-row: 1;
        }
    }
}

.link {
    color: var(--s-color-text-primary);
    font-size: var(--g-font-size-300);
    font-weight: var(--g-font-weight-regular);
    line-height: var(--g-line-height-300);
    text-decoration: none;
    margin: 0;
    &:focus-visible {
        color: var(--s-color-brand-primary-standard);
        text-decoration: underline;
        outline-color: currentColor;
        outline-width: var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        border-radius: var(--c-header-link-outline-border-radius-focus);
    }
    &:hover,
    &:active {
        color: var(---color-brand-primary-standard);
        text-decoration: underline;
        transition-duration: var(--g-duration-xshort);
    }
}

.registration-numbers {
    padding-left: var(--g-spacing-sm);
    font-size: var(--g-font-size-200);
    @include breakpoint-sm {
        padding-left: 0;
        font-size: var(--g-font-size-400);
    }
}
