@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .nav-wrapper {
        align-items: center;
        border-radius: var(--s-border-radius-background-standard);
        bottom: var(--g-spacing-md);
        display: inline-flex;
        justify-content: flex-end;
        min-height: 24px;
        position: absolute;
        left: 50%;
        // HACK: Stop nav from rendering behind the slider in safari
        transform: translate3d(-50%, 0, 0);
        z-index: 2;

        &:before {
            content: "";
            // workaround for converting hex to rgba color for all browsers
            background-color: var(--g-color-black);
            border-radius: var(--s-border-radius-background-standard);
            opacity: var(--g-opacity-quiet);
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }
    }

    .slide-indicator {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        font-size: var(--g-font-size-200);
        line-height: var(--g-line-height-200);
        color: var(--g-color-white);
        min-width: 24px;
        background-color: transparent;
        p {
            margin: 0;
        }
    }

    .button {
        background-color: transparent;
        border: none;
        color: var(--g-color-white);
        cursor: pointer;
        display: flex;
        height: 20px;
        padding: 0;
        width: 20px;
        z-index: 1;

        &:focus-visible {
            outline: var(--g-border-width-sm) solid var(--g-color-white);
            border-radius: var(--s-border-radius-background-standard);
        }

        svg {
            width: 20px;
            height: 20px;
            display: inline-flex;
            color: currentColor;
            align-items: center;
            justify-content: center;
        }
    }
}
