.custom-color {
    svg {
        fill: var(--lottie-color);
        stroke: var(--lottie-color);
    }
    path {
        fill: var(--lottie-color);
        stroke: var(--lottie-color);
    }
}
