@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wishlist-button {
        align-items: center;
        border: none;
        cursor: pointer;
        display: flex;
        height: 36px;
        justify-content: center;
        width: 36px;
        border-radius: 50%;
        transition: box-shadow opacity var(--g-duration-xshort) ease;
        background-color: var(--s-color-surface-secondary);
        box-shadow: var(--c-wishlist-button-shadow-default);
        opacity: 0.7;
        color: var(--s-color-icon-primary);

        &:hover,
        &:focus-visible,
        &:active {
            opacity: 1;
            box-shadow: var(--c-wishlist-button-shadow-active);
        }

        &:focus-visible {
            outline: var(--g-border-width-sm) solid
                var(--s-color-brand-primary-standard);
        }

        &--selected {
            opacity: 1;
            box-shadow: var(--c-wishlist-button-shadow-default);
        }
    }

    .wishlist-lottie-icon {
        color: currentColor;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
    }

    .wishlist-placeholder-icon {
        color: currentColor;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .modal-title {
        line-height: 150%;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        padding: 0 var(--g-spacing-xl);
        text-align: center;
        padding-bottom: var(--g-spacing-xl);
        border-bottom: 1px solid var(--s-color-separator-standard);
        margin: 0;

        @include breakpoint-md {
            line-height: 130%;
            font-size: 24px;
        }
    }

    .modal-actions {
        padding: var(--g-spacing-md) 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--g-spacing-md);
    }
}
