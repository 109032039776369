@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .graphic-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: var(--g-spacing-lg);
    }

    .graphic-list-two-across {
        @include breakpoint-md {
            grid-template-columns: repeat(2, 1fr);
            column-gap: var(--g-spacing-xl);
            grid-auto-flow: column;
            grid-template-rows: repeat(var(--row-count), 1fr);
            row-gap: var(--g-spacing-xl);
        }
    }
}
