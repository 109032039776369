@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .container {
        overflow: auto;
        display: flex;

        &--min-height {
            min-height: 300px;
            @include for-tablet-up {
                min-height: 400px;
            }
        }
    }

    .hero-background-color {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--section-background-color);
    }

    .hero-background-gradient {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            var(--section-background-gradient-angle),
            var(--section-background-start-color),
            var(--section-background-end-color)
        );
    }
    .hero-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: var(--g-spacing-xl);
        margin-bottom: var(--g-spacing-xl);
        overflow: hidden;
        position: relative;

        @include for-tablet-up {
            margin-top: var(--g-spacing-xxl);
            margin-bottom: var(--g-spacing-xxl);
        }

        &--x-center {
            align-items: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
        &--x-left {
            align-items: start;
            text-align: left;
            margin-left: 0;
        }
        &--full {
            max-width: var(--c-container-full);
        }

        &--xlarge {
            max-width: var(--c-container-xl);
        }

        &--large {
            max-width: var(--c-container-lg);
        }

        &--medium {
            max-width: var(--c-container-md);
        }

        &--small {
            max-width: var(--c-container-sm);
        }

        &--xsmall {
            max-width: var(--c-container-xs);
        }
    }

    .hero-item {
        position: relative;

        &:not(:last-child) {
            padding-bottom: var(--g-spacing-md);
        }
    }

    .action-item {
        display: block;

        &:not(:last-child) {
            margin-bottom: var(--g-spacing-md);
        }
    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, var(--overlay-opacity, 0.2));
    }

    .background {
        left: 0;
        position: absolute;
        top: 0;
    }

    .wistia-container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        aspect-ratio: 16/9;
    }

    .video-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
