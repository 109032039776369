@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .media-item {
        display: flex;
        overflow: hidden;
        max-width: 100%;
        flex-direction: column;

        & > * {
            width: 100%;
            height: auto;
        }
    }
}
