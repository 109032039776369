@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .lightning-deal-card {
        display: flex;
        flex-direction: row-reverse;
        overflow: hidden;
        padding: 0;
        border: 0;
        width: 100%;
        text-align: left;
        min-height: 230px;
        position: relative;

        &:hover:not(&--sold-out),
        &:focus-within:not(&--sold-out) {
            box-shadow: (var(--s-shadow-standard-active));
        }

        @media (forced-colors: active) {
            border: var(--g-border-width-sm) solid transparent;
        }
    }

    .wishlist-button-wrapper {
        position: absolute;
        top: var(--g-spacing-sm);
        left: var(--g-spacing-sm);
        z-index: 2;
    }

    .type {
        display: flex;
        align-items: center;
        flex-direction: row;
        img {
            margin-right: var(--g-spacing-xs);
        }
    }

    .image {
        overflow: hidden;
        position: relative;
        height: auto;
        width: var(--c-tour-card-grid-image-width);
        flex-shrink: 0;
        aspect-ratio: auto;
    }
    .content {
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: space-between;
        overflow: hidden;
        align-items: flex-start;
        padding: var(--g-spacing-md);
        font-family: var(--g-font-family-primary), sans-serif;
        font-size: var(--g-font-size-300);
        width: 100%;
        > div {
            width: 100%;
        }
    }
    .title {
        text-decoration: none;
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-bold);
        line-height: var(--g-line-height-400);
        margin-bottom: var(--g-spacing-xxs);
        max-height: calc(var(--g-line-height-400) * 3 * 1em);
    }

    .anchor {
        width: 100%;
        text-decoration: none;
        display: block;
        &:before {
            content: "";
            position: absolute;
            display: block;
            height: 100%;
            width: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
        }

        &:focus-visible {
            outline: var(--g-border-width-sm) solid
                var(--s-color-brand-primary-standard);
            outline-offset: unset;
            transition: none;
        }
    }

    .badge {
        margin-bottom: var(--g-spacing-sm);
    }
    .price {
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-light);
        > span:not(:last-child) {
            margin-right: var(--g-spacing-xs);
        }
        .original-price {
            &--strike-through {
                text-decoration: line-through;
            }
        }
        .sale-price {
            color: var(--s-color-promo-price-standard);
            font-weight: var(--g-font-weight-bold);
        }
    }
    .duration {
        font-weight: var(--g-font-weight-light);
        margin-bottom: var(--g-spacing-md);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
    .discount {
        margin-top: var(--g-spacing-xxs);
        font-weight: var(--g-font-weight-medium);
    }
    .departure {
        margin-top: var(--g-spacing-sm);
        &:hover {
            color: var(--s-color-text-primary);
        }
    }

    .lightning-deal-card--sold-out {
        .badge,
        .discount {
            span {
                color: var(--s-color-text-primary);
                background: var(--s-color-neutral-background);
                cursor: default;
            }
        }
        .duration,
        .price,
        .title,
        .departure,
        .sale-price {
            color: var(--s-color-disabled-primary);
            cursor: default;
        }
        img {
            filter: grayscale(100%);
        }
    }
}
