@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .container {
        display: grid;
        grid-template-columns: none;
        overflow: hidden;
        background-color: var(--background-color);
        max-height: max-content;
        grid-template-areas:
            "media"
            "content";
        position: relative;

        @include breakpoint-lg {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: "media content";
            min-height: 400px;
        }

        &--reverse {
            @include breakpoint-lg {
                grid-template-areas: "content media";
            }
        }
    }

    .media {
        display: flex;
        grid-area: media;
        width: 100%;
        height: 300px;

        @include breakpoint-md {
            height: 400px;
        }
        @include breakpoint-lg {
            position: absolute;
            height: 100%;
        }
    }

    .video--autoplay {
        button {
            @include breakpoint-lg {
                left: var(--g-spacing-sm);
            }
        }

        &--reverse {
            button {
                @include breakpoint-lg {
                    left: auto;
                    right: var(--g-spacing-sm);
                }
            }
        }
    }

    .content {
        display: flex;
        grid-area: content;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: var(--g-spacing-lg);

        @include breakpoint-md {
            padding: var(--g-spacing-xl);
        }
        @include breakpoint-lg {
            padding: var(--g-spacing-xxl);
        }
        @include breakpoint-xl {
            & > * {
                max-width: 704px;
            }
        }

        &--background-color {
            background: var(--background-color);
        }

        &--background-gradient {
            background: linear-gradient(
                var(--gradient-angle),
                var(--background-start-color),
                var(--background-end-color)
            );
        }

        &--x-left {
            align-items: flex-start;
        }

        &--x-center {
            align-items: center;
            text-align: center;
        }
    }

    .actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: var(--g-spacing-lg);
        align-items: center;
        gap: var(--g-spacing-md);

        &--left {
            align-items: flex-start;
        }

        &--center {
            align-items: center;
        }
    }
}
