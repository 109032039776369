@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .review-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .spacer {
        margin-right: var(--g-spacing-xs);
    }
    .rating {
        margin-bottom: var(--g-spacing-md);
    }
    .title {
        margin-bottom: var(--g-spacing-xs);
    }
    .name {
        margin-bottom: var(--g-spacing-md);
    }
    .description {
        margin-bottom: var(--g-spacing-lg);
    }
    .tour {
        margin-top: auto;
    }

    .link {
        color: var(--g-color-primary-standard);
    }

    .cta {
        margin-top: var(--g-spacing-md);
    }
}
