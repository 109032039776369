@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wrapper {
        display: flex;
        border-radius: 0;
        padding: 0;
        border-width: 0;
        overflow: auto;

        &-border-radius {
            border-radius: var(--s-border-radius-background-standard);
        }

        &:link,
        &:visited,
        &:hover,
        &:active {
            text-decoration: none;
        }

        @media (forced-colors: active) {
            border-width: 2px;
        }
    }
}
