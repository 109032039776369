@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .list-item {
        & + & {
            border-top-color: var(--s-color-separator-standard);
            border-top-style: solid;
            border-top-width: var(--g-border-width-xs);
        }
    }

    .inner-wrapper {
        color: var(--s-color-text-primary);
        display: grid;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-regular);
        gap: var(--g-spacing-md);
        grid-template-columns: minmax(max-content, 1fr) auto;
        line-height: var(--g-line-height-400);
        list-style-type: none;
        padding: var(--g-spacing-md);
        position: relative;

        @media (min-width: 375px) {
            grid-template-columns: max-content minmax(max-content, 1fr) 1fr;
        }

        @include breakpoint-md {
            font-size: var(--g-font-size-300);
            grid-template-columns:
                max-content
                140px 1fr 120px;
            line-height: var(--g-line-height-300);
            margin: auto;
            max-width: 600px;
            gap: var(--g-spacing-md);
            padding: var(--g-spacing-sm) var(--g-spacing-md);
        }

        @media (min-width: 1024px) {
            font-size: var(--g-font-size-400);
            grid-template-columns:
                max-content
                170px 1fr 120px;
            line-height: var(--g-line-height-400);
            max-width: none;
        }
    }

    .circle-wrapper {
        display: none;

        @media (min-width: 375px) {
            display: block;
        }
    }

    .circle {
        border-color: var(--s-color-neutral-border);
        border-style: solid;
        border-width: var(--g-border-width-xs);
        border-radius: 50%;
        box-sizing: border-box;
        display: block;
        height: 20px;
        width: 20px;
    }

    .dates-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-xs);

        @include breakpoint-md {
            white-space: nowrap;
        }
    }

    .date-range {
        color: var(--s-color-text-primary);
        font-weight: var(--g-font-weight-bold);
        text-decoration: none;

        &:focus-visible {
            border-radius: var(--s-border-radius-interactive-focus);
            color: var(--s-color-brand-primary-standard);
            outline-color: currentColor;
            outline-offset: var(--g-spacing-xxs);
            outline-style: solid;
            outline-width: var(--g-border-width-sm);
            text-decoration: underline;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .availability-mobile {
        display: flex;

        @include breakpoint-md {
            display: none;
        }
    }

    .availability-desktop {
        align-items: center;
        display: none;

        @include breakpoint-md {
            display: flex;
        }
    }

    .extra-info {
        font-size: var(--g-font-size-300);
        line-height: var(--g-line-height-300);
        font-weight: var(--g-font-weight-light);
    }

    .pricing-wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;

        @include breakpoint-md {
            align-items: center;
            flex-direction: row;
            gap: var(--g-spacing-sm);
            justify-self: center;
        }
    }

    .classlevel {
        display: flex;
        align-items: flex-start;
        gap: var(--g-spacing-xs);
        img {
            color: var(--s-color-icon-primary);
        }

        &--dark {
            img {
                filter: invert(1);
            }
        }
    }

    .price {
        font-weight: var(--g-font-weight-light);

        &--strike-through {
            text-decoration: line-through;
        }
    }

    .sales-price {
        color: var(--s-color-promo-price-standard);
        font-weight: var(--g-font-weight-bold);
    }

    .desktop-only--inline {
        display: none;

        @include breakpoint-md {
            display: inline;
        }
    }
}
