@use "@wojo/ui/mixins" as *;
@mixin spacing($property) {
    &--none {
        #{$property}: 0;
    }

    &--xxsmall {
        #{$property}: var(--g-spacing-xxs);
    }

    &--xsmall {
        #{$property}: var(--g-spacing-xs);
    }

    &--small {
        #{$property}: var(--g-spacing-sm);
    }

    &--medium {
        #{$property}: var(--g-spacing-md);
    }

    &--large {
        #{$property}: var(--g-spacing-lg);
    }

    &--large-responsive {
        #{$property}: var(--g-spacing-lg);

        @include breakpoint-md {
            #{$property}: var(--g-spacing-xl);
        }
    }

    &--xlarge {
        #{$property}: var(--g-spacing-xl);
    }

    &--xlarge-responsive {
        #{$property}: var(--g-spacing-xl);

        @include breakpoint-md {
            #{$property}: var(--g-spacing-xxl);
        }
    }

    &--2xlarge {
        #{$property}: var(--g-spacing-xxl);
    }
}
@include layer(storyblok) {
    .space-above {
        @include spacing(margin-top);
    }

    .space-below {
        @include spacing(margin-bottom);
    }

    .inner-spacing-top {
        @include spacing(padding-top);
    }

    .inner-spacing-bottom {
        @include spacing(padding-bottom);
    }
}
