@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .image-graphic-wrapper {
        display: inline-flex;
    }

    .image-graphic {
        display: inline-flex;

        img {
            object-fit: contain;
            height: calc(var(--mobile-image-height));
            max-width: 100%;

            @include breakpoint-md {
                height: var(--desktop-image-height);
            }
        }
    }
}
