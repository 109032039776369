@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: var(--g-font-family-primary);
        gap: var(--g-spacing-lg);
        position: relative;
        justify-content: center;
        z-index: 0;

        &-background {
            padding-top: var(--g-spacing-xl);
            padding-bottom: var(--g-spacing-xl);
            min-height: 300px;

            @include breakpoint-md {
                min-height: 450px;
            }
        }
    }

    .background {
        left: 0;
        position: absolute;
        top: 0;
    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, var(--overlay-opacity, 0.2));
    }

    .quoteMark {
        font-size: 85px;
        font-weight: var(--font-weight-bold);
        line-height: 80%;
        height: 24px;
        position: relative;
    }

    .quoteText {
        margin: 0;
        font-weight: var(--font-weight-bold);
        text-align: center;
        font-size: var(--g-font-size-600);
        line-height: var(--g-line-height-600);
        position: relative;
        padding: 0 var(--g-spacing-xl);
        max-width: var(--c-container-md);

        @include for-tablet-up {
            font-size: var(--g-font-size-700);
            line-height: var(--g-line-height-700);
        }
    }

    .authorNameText {
        font-weight: var(--g-font-weight-regular);
        margin: 0;
        position: relative;
    }

    .authorContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: var(--g-spacing-sm);
        position: relative;
    }

    .authorImage {
        img {
            height: 60px;
            width: 60px;
            border-radius: 100%;
        }
    }
}
