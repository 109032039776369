@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .slider-wrapper {
        padding: 0;
    }

    .slider-container {
        &--horizontal {
            --aspect-ratio: 4/3;
        }

        &--vertical {
            --aspect-ratio: 6/7;
        }

        &--vertical,
        &--horizontal {
            @include breakpoint-sm {
                --aspect-ratio: 3/2;
            }
            @include breakpoint-md {
                --aspect-ratio: 16/10;
            }
            @include breakpoint-lg {
                --aspect-ratio: 16/9.7;
            }
            @include breakpoint-xl {
                --aspect-ratio: 16/9.5;
            }
        }
    }

    .slider-nav {
        margin-top: 0;
    }

    .slide {
        aspect-ratio: var(--aspect-ratio);
        display: flex;
        width: 100%;
        justify-content: center;
        overflow: hidden;
        height: 100%;

        & > * {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            max-height: 100%;
            width: auto;
            overflow: hidden;
        }

        picture {
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 100%;
        }

        img {
            max-width: 100%;
        }
    }

    .slider-container {
        &--vertical {
            .slide {
                & > * {
                    justify-content: center;

                    @include breakpoint-md {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}
