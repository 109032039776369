@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .images {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: var(--g-spacing-md);

        button {
            padding: 0;
            border: 0;
            background: none;
            border-radius: 0;
            margin-right: var(--g-spacing-sm);

            &:focus-visible {
                outline: var(--g-border-width-sm) solid
                    var(--s-color-brand-primary-standard);
                outline-offset: var(--g-spacing-xxs);
            }

            &:active {
                transform: scale(0.96);
                transition: transform var(--g-duration-xshort) ease;

                @media (prefers-reduced-motion: reduce) {
                    transition: none;
                }
            }
        }

        .image {
            width: 60px;
            height: 60px;
            overflow: hidden;
            position: relative;

            @include breakpoint-md {
                width: 80px;
                height: 80px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .overlay {
                position: absolute;
                top: 0;
                right: 0;
                background-color: rgba(0, 0, 0, var(--g-opacity-quiet));
                color: var(--g-color-white);
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: var(--g-font-size-500);
                font-weight: var(--g-font-weight-bold);
                font-family: var(--g-font-family-primary), serif;
            }
        }
    }
}
