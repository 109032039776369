@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wrapper {
        color: var(--s-color-text-primary);
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-regular);
        line-height: var(--g-line-height-400);
        outline: var(--g-border-width-xs) solid transparent;
        overflow: hidden;
        padding: 0;

        @include breakpoint-md {
            display: none !important;
        }
    }

    .card-header {
        display: grid;
        grid-template-columns: 109px 1fr;
        position: relative;
    }

    .tour-details {
        height: 162px;
        order: 1;
        padding: var(--g-spacing-md);
    }

    .tour-image {
        order: 0;
        overflow: hidden;

        picture {
            display: flex;
            height: 100%;
        }

        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }

    .merchandising-label {
        display: flex;
        margin-bottom: var(--g-spacing-md);
    }

    .product-page-link {
        display: block;
        color: var(--s-color-text-primary);
        font-weight: var(--g-font-weight-bold);
        text-decoration: none;

        @supports (-webkit-line-clamp: 3) {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            max-height: 3lh;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:focus-visible {
            border-radius: var(--s-border-radius-interactive-focus);
            color: var(--s-color-brand-primary-standard);
            outline-color: currentColor;
            outline-offset: var(--g-spacing-xxs);
            outline-style: solid;
            outline-width: var(--g-border-width-sm);
            text-decoration: underline;
        }

        &::before {
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .trip-length {
        font-weight: var(--g-font-weight-light);

        @supports (-webkit-line-clamp: 1) {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            max-height: 1lh;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .rating-wrapper {
        display: flex;
        padding-top: var(--g-spacing-sm);
    }

    .wishlist {
        position: absolute;
        left: var(--g-spacing-sm);
        top: var(--g-spacing-sm);
        color: var(--s-color-brand-primary-standard);
    }

    .deals-list {
        border-color: var(--s-color-separator-standard);
        border-style: solid;
        border-width: var(--g-border-width-xs) 0;
        margin: 0;
        padding: 0;
        list-style: none;

        &--no-bottom-border {
            border-bottom: none;
        }
    }

    .trip-builder-link-wrapper {
        display: block;
        padding: var(--g-spacing-md) 0;
        position: relative;
        text-align: center;

        a {
            position: static;

            &::before {
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }
}
